import {
  HubspotPropertyMapping,
  HubspotPropertyMappingList,
  HubspotPropertyMappingObjectType,
} from '@/audit/api/types.ts';
import { AuditPropertyValue } from '@/audit/components/audit-property-value.tsx';
import { ArrowForwardRounded } from '@mui/icons-material';
import { Typography } from '@mui/material';

interface Props {
  property: HubspotPropertyMappingList;
}

const hubspotPropertyMappingTitles: Record<
  HubspotPropertyMappingObjectType,
  string
> = {
  COMPANY: 'Customer mapping',
  DEAL: 'Project mapping',
  LINE_ITEM: 'Line item mapping',
};

export function AuditHubspotPropertyMapping(props: Props) {
  if (
    props.property.newValue.length === 0 &&
    props.property.oldValue.length === 0
  ) {
    return null;
  }

  const propsByObjectTypeId: Record<
    HubspotPropertyMappingObjectType,
    HubspotPropertyMapping[]
  > = {
    COMPANY: [],
    DEAL: [],
    LINE_ITEM: [],
  };
  props.property.newValue.forEach((value) => {
    const propertyObjectTypeId = value.propertyObjectTypeId;
    if (!propsByObjectTypeId[propertyObjectTypeId]) {
      propsByObjectTypeId[propertyObjectTypeId] = [];
    }
    propsByObjectTypeId[propertyObjectTypeId].push(value);
  });

  const oldValues: Record<string, HubspotPropertyMapping> = {};
  props.property.oldValue?.forEach((value) => {
    oldValues[value.id] = value;
  });
  return (
    <>
      {Object.entries(propsByObjectTypeId).map(([objectTypeId, values]) => {
        if (values.length === 0) {
          return null;
        }
        return (
          <Typography variant="caption">
            <Typography component="span" variant="inherit" fontWeight="bold">
              {
                hubspotPropertyMappingTitles[
                  objectTypeId as HubspotPropertyMappingObjectType
                ]
              }
              :&nbsp;
            </Typography>
            <ul style={{ padding: 0, margin: '0 0 0 20px' }}>
              {values.map((value) => {
                const oldValue = oldValues[value.id];
                if (oldValue.value === value.value) {
                  return null;
                }
                return (
                  <li style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      component="span"
                      variant="inherit"
                      fontWeight="bold"
                    >
                      {value.label}:&nbsp;
                    </Typography>
                    <AuditPropertyValue value={oldValue.valueLabel ?? null} />
                    <ArrowForwardRounded
                      fontSize="inherit"
                      sx={{ marginX: 1 }}
                    />
                    <AuditPropertyValue value={value.valueLabel} />
                  </li>
                );
              })}
            </ul>
          </Typography>
        );
      })}
    </>
  );
}
