import { NavigateNextRounded } from '@mui/icons-material';
import { Box, Button, Divider, Paper, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

interface AuditCardProps {
  title: string;
  subtitle: string;
  auditLogText?: string;
  onClick: () => void;
}

export function AuditCard(props: PropsWithChildren<AuditCardProps>) {
  return (
    <Paper variant="outlined" sx={{ overflow: 'hidden' }}>
      <Box
        padding={2}
        bgcolor="grey.50"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography variant="overline" marginRight="auto">
            {props.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {props.subtitle}
          </Typography>
        </Box>
        <Button
          type="submit"
          size="small"
          onClick={props.onClick}
          endIcon={<NavigateNextRounded fontSize="small" />}
          sx={{ ml: 2 }}
        >
          {props.auditLogText ? props.auditLogText : 'View audit log'}
        </Button>
      </Box>
      <Divider />
      {props.children}
    </Paper>
  );
}
