import { executeMultiMove, planMultiMove } from '@/asset/api/multi-move';
import { FormAssetSearch } from '@/asset/components/form-asset-search';
import {
  MultiMoveFormData,
  MultiMoveRowStatus,
  PlannedMove,
  checkMultiMoveIntersections,
  errorHasCustomErrors,
  getPlannedMoveDetails,
  getPlannedMoveValidationStatus,
  multiMoveFormKeys,
  multiMoveFormSchema,
} from '@/asset/utilities/asset-multi-move';
import { useCurrentUser } from '@/authentication/hooks/use-current-user';
import { ConditionalTooltip } from '@/components/conditional-tooltip';
import { FormDateTimePickerV2 } from '@/components/form-date-time-picker';
import { SupportAlert } from '@/components/support-alert';
import { FormLocationSearch } from '@/location/components/form-location-search';
import { getNewLocationURL } from '@/location/utilities/get-new-location-url';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  AddTaskOutlined,
  CancelOutlined,
  ChangeCircleOutlined,
  CheckCircleOutlined,
  Close,
  DeleteRounded,
  LocalShippingOutlined,
  ScheduleOutlined,
} from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  IconButton,
  alpha,
  useTheme,
} from '@mui/material';
import { blue, green, grey, red } from '@mui/material/colors';
import { isEqual } from 'lodash';
import { DateTime } from 'luxon';
import {
  ReactElement,
  isValidElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';

interface Props {
  onClose: () => void;
}

const debounceMs = 1000;

function fuseAltered(
  current: PlannedMove['altered'],
  updates: Partial<PlannedMove['altered']>
): PlannedMove['altered'] {
  return {
    asset: current.asset || updates.asset || false,
    origin: current.origin || updates.origin || false,
    destination: current.destination || updates.destination || false,
    shipDate: current.shipDate || updates.shipDate || false,
    deliveryDate: current.deliveryDate || updates.deliveryDate || false,
  };
}

/**
 * A custom hook that manages the state for multi-move operations, including tracking planned moves,
 * error messages, submission status, and network request statuses.
 *
 * @returns {Object} An object containing state variables and their respective setter functions:
 * - `plannedMoves`: Array of planned moves to be executed.
 * - `setPlannedMoves`: Function to update the list of planned moves.
 * - `errorMessage`: Boolean or null indicating the presence of an error.
 * - `setErrorMessage`: Function to set or clear the error state.
 * - `isSubmitting`: Boolean indicating whether a submission is currently in progress.
 * - `setIsSubmitting`: Function to toggle the submission status.
 * - `requestInProgress`: Boolean indicating whether a network request is currently active.
 * - `setRequestInProgress`: Function to update the network request status.
 * - `queuedSubmit`: Function or null representing a deferred submit action.
 * - `setQueuedSubmit`: Function to set or clear the deferred submit action.
 */
const useMultiMoveState = () => {
  const [plannedMoves, setPlannedMoves] = useState<PlannedMove[]>([]);
  const [errorMessage, setErrorMessage] = useState<
    ReactElement | boolean | null
  >(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [queuedSubmit, setQueuedSubmit] = useState<(() => void) | null>(null);
  const [submissionComplete, setSubmissionComplete] = useState<boolean>(false);

  return {
    plannedMoves,
    setPlannedMoves,
    errorMessage,
    setErrorMessage,
    isSubmitting,
    setIsSubmitting,
    requestInProgress,
    setRequestInProgress,
    queuedSubmit,
    setQueuedSubmit,
    submissionComplete,
    setSubmissionComplete,
  };
};

export function AssetMultiMove(props: Props) {
  // Track all planned moves using a ref.
  // Don't want to useState here because detecting no-op changes becomes quite complicated.
  const {
    plannedMoves,
    setPlannedMoves,
    errorMessage,
    setErrorMessage,
    isSubmitting,
    setIsSubmitting,
    setQueuedSubmit,
    queuedSubmit,
    setRequestInProgress,
    requestInProgress,
    submissionComplete,
    setSubmissionComplete,
  } = useMultiMoveState();

  const theme = useTheme(); // Ensure MUI Theme is available
  const currentUser = useCurrentUser();
  const { organization } = currentUser.user;

  const removePlannedMoveState = useCallback(
    (id: string[]) => {
      const newState = plannedMoves.filter((item) => !id.includes(item.id));
      setPlannedMoves(newState);
    },
    [plannedMoves, setPlannedMoves]
  );
  // A helper function to update the ref
  const updatePlannedMoveState = useCallback(
    (updates: PlannedMove[]) => {
      // Create a temporary shallow copy of the ref state
      const updatedState = [...plannedMoves];

      let wasChanged = false;
      // Update each status
      for (const { id, ...extra } of updates) {
        const index = updatedState.findIndex((item) => item.id === id);

        if (index !== -1) {
          const proposed = { id, ...extra };
          if (!isEqual(proposed, updatedState[index])) {
            wasChanged = true;
          }
          updatedState[index] = { id, ...extra };
        } else {
          wasChanged = true;
          updatedState.push({ id, ...extra });
        }
      }
      if (wasChanged) {
        // Flush state
        setPlannedMoves(updatedState);
      }
    },
    [setPlannedMoves, plannedMoves]
  );

  // Handle queued requests from when request in progress and submit is clicked
  useEffect(() => {
    if (!requestInProgress && queuedSubmit) {
      queuedSubmit();
      setQueuedSubmit(null);
    }
  }, [requestInProgress, queuedSubmit, setQueuedSubmit]);

  // Create a new form that handles an array of rows
  const { control, getValues, trigger, getFieldState, setError } =
    useForm<MultiMoveFormData>({
      resolver: zodResolver(multiMoveFormSchema),
      defaultValues: {
        moves: [
          {
            id: crypto.randomUUID().toString(),
            asset: null,
            origin: null,
            destination: null,
            shipDate: null,
            deliveryDate: null,
          },
        ],
      },
    });

  // Set up a fieldArray helper
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'moves',
  });

  // Watch the form for data changes
  const proposedMoves = useWatch({ name: 'moves', control });

  // Primary effect for proposedMoves updates
  useEffect(() => {
    // Create a list of updates that should be processed in this effect
    const updates: PlannedMove[] = [];

    // We have a mix of form controlled errors from zod + custom multi-row/remote logic that is handled by this
    // In order to not run triggering improperly (which would clear errors even if the state is still in error)
    // a map of the field index and if it has a custom error is created
    const rowsWithCustomErrors = checkMultiMoveIntersections(
      proposedMoves,
      setError
    );

    // Find any elements in plannedMoves that are not in proposedMoves by their id
    const missingMoves = plannedMoves.filter(
      (plannedMove) =>
        !proposedMoves.some(
          (proposedMove) => proposedMove.id === plannedMove.id
        )
    );

    proposedMoves.forEach((move, index) => {
      const moveId = move.id;
      // Get the existing data
      const staleData = plannedMoves.find((item) => item.id === moveId);
      if (!staleData) {
        // New record
        updates.push({
          isValidating: false,
          id: moveId,
          status: 'unknown',
          asset: move.asset,
          shipDate: move.shipDate,
          deliveryDate: move.deliveryDate,
          destination: move.destination,
          origin: move.origin,
          altered: {
            asset: false,
            origin: false,
            destination: false,
            shipDate: false,
            deliveryDate: false,
          },
        });
        return;
      }

      // Collect various data elements that this is interested in
      const assetData = move.asset;
      const origin = move.origin;
      const destination = move.destination;
      const shipDateData = move.shipDate;
      const deliveryDateData = move.deliveryDate;

      // See if some of the more complex objects changed
      const assetUpdated = !isEqual(assetData, staleData.asset);
      const originUpdated = !isEqual(origin, staleData.origin);
      const destinationUpdated = !isEqual(destination, staleData.destination);
      const shipDateUpdated = shipDateData !== staleData.shipDate;
      const deliveryDateUpdated = deliveryDateData !== staleData.deliveryDate;
      // Check for changes against the existing record
      const hasChanges =
        assetUpdated ||
        originUpdated ||
        destinationUpdated ||
        shipDateUpdated ||
        deliveryDateUpdated;

      // Get the current error state
      const { error } = getFieldState(`moves.${index}`);
      const hasCustomError = errorHasCustomErrors(error);

      // Check to see if there's a server side error, if there is
      // update the state that there is a custom error,
      // when the appropriate value changes it will clear this error automatically.
      if (staleData.lastIntersectionError) {
        rowsWithCustomErrors.set(index, true);
      }

      if (rowsWithCustomErrors.get(index) === false) {
        // Re-trigger any fields that have no custom errors
        // This allows Row N change to have a side effect to re-validate row Y
        if (staleData.status !== 'unknown') {
          trigger(`moves.${index}`);
        }
      }

      if (!hasChanges) {
        // No changes were detected, see if there was some error state update
        // if not, no op.

        // Get the existing status value
        let rowState: MultiMoveRowStatus = staleData.status;

        if (error || rowsWithCustomErrors.get(index)) {
          rowState = 'invalid';
        } else if (rowState === 'invalid') {
          // If it was in an error state, push it back to a pending state
          rowState = 'pending';
        }
        if (staleData.status === rowState) {
          // No op
          return;
        }
        // Some error state got pushed off during validation and needs a refresh
        updates.push({
          ...staleData,
          status: rowState,
          isValidating: false,
          runAfter:
            rowState === 'pending'
              ? new Date().getTime() + debounceMs
              : undefined,
        });
        return;
      }

      // Coalesce the current move data based off the current form state
      const currentData = {
        ...staleData,
        asset: assetData,
        shipDate: shipDateData,
        deliveryDate: deliveryDateData,
        destination: destination,
        origin: origin,
        // Clear the intersection error if there was a change in ship or deliver date
        lastIntersectionError:
          shipDateUpdated || deliveryDateUpdated
            ? undefined
            : staleData.lastIntersectionError,
      };

      // This stores a fused state if fields for this row have ever been altered.
      // Primary use is to avoid coloring rows when people are still filling out
      // data before it ever has the chance to be valid.
      const altered = fuseAltered(staleData.altered, {
        asset: assetUpdated,
        origin: originUpdated,
        destination: destinationUpdated,
        shipDate: shipDateData !== staleData.shipDate,
        deliveryDate: deliveryDateData !== staleData.deliveryDate,
      });

      // Require that asset, origin, destination be filled out and that one of the dates is filled out
      if (
        !(altered.asset && altered.origin && altered.destination) ||
        !(altered.shipDate || altered.deliveryDate)
      ) {
        // This early exists before validation states get tossed onto it to give the user a chance to fill stuff out.
        updates.push({
          ...currentData,
          status: hasCustomError ? 'invalid' : 'unknown',
          isValidating: false,
          altered,
        });
        return;
      }
      // Check the general error state on all fields
      // This has to be triggered so that "superRefine" works
      // clear the errors before the trigger runs
      trigger(`moves.${index}`).then((success) => {
        const readyToSubmit = multiMoveFormKeys.every((key) => {
          if (key.required) {
            const value = getValues(`moves.${index}.${key.id}`);
            return value !== null;
          }
          return true;
        });
        let rowState: MultiMoveRowStatus = 'unknown';
        if (!success || hasCustomError) {
          rowState = 'invalid';
        } else if (readyToSubmit) {
          rowState = 'pending';
        }
        // Push a status update for this row so it gets validated in the background
        updatePlannedMoveState([
          {
            ...currentData,
            status: rowState,
            // If the row state is pending, debounce
            runAfter:
              rowState === 'pending'
                ? new Date().getTime() + debounceMs // Take now + the debounceMS to allow for extra updates
                : undefined,
          },
        ]);
      });
    });
    // Push the final state that gets updated in one go for any updates not being triggered.
    if (updates.length > 0) {
      updatePlannedMoveState(updates);
    }
    if (missingMoves.length > 0) {
      removePlannedMoveState(missingMoves.map((item) => item.id));
    }
  }, [
    proposedMoves,
    updatePlannedMoveState,
    removePlannedMoveState,
    getFieldState,
    getValues,
    setError,
    trigger,
    plannedMoves,
  ]);
  // Handle callbacks after a new location is requesting to be added
  const handleCreateLocation = (name: string) => {
    const url = getNewLocationURL(name);

    window.open(url, '_blank');
  };

  /**
   * A callback function that validates and processes pending planned moves, and updates their state.
   *
   * @param {boolean} runNow - Indicates whether the validation and processing of pending items should override the scheduled `runAfter` time.
   *
   * This function filters planned moves with a `pending` status and determines if they are eligible for validation
   * based on their `runAfter` timestamp or if the `runNow` flag is true. It then processes these pending moves:
   *
   * 1. Creates a set of parameters for eligible moves to be processed by calling an external `planMultiMove` API.
   * 2. Updates the state of planned moves based on the response from the API, including their validation status,
   *    and handles errors such as shipment conflicts.
   * 3. Updates validation errors for conflicting shipment dates (`shipDate` and `deliveryDate`) in the form state.
   * 4. Updates the global state for planned moves with new statuses and validation indicators.
   *
   * Dependencies:
   * - `plannedMoves` and `proposedMoves`: Arrays containing planned move data to be validated.
   * - `setError`: A function to set validation errors for conflicting moves.
   * - `updatePlannedMoveState`: A function to update the state of planned moves.
   * - `planMultiMove`: An API function that validates multiple moves.
   * - `setRequestInProgress`: A function to indicate whether the request is in progress.
   */
  const runCheckPendingItems = useCallback(
    async (runNow: boolean) => {
      const now = new Date().getTime();
      const pendingItems = plannedMoves.filter(
        (item) =>
          item.status === 'pending' &&
          item.runAfter !== undefined &&
          (item.runAfter <= now || runNow)
      );
      if (pendingItems.length > 0) {
        setRequestInProgress(true);
        const params = pendingItems
          .filter(
            (
              item
            ): item is typeof item & {
              shipDate: string;
              asset: { id: string };
            } => item.shipDate !== null && item.asset !== null
          )
          .map((item) => {
            return {
              id: item.id,
              assetId: item.asset.id,
              shipAt: item.shipDate ?? undefined,
              deliverAt: item.deliveryDate ?? undefined,
            };
          });
        const multiMoveResponse = await planMultiMove(params);
        const updates: PlannedMove[] = [];
        multiMoveResponse.data.map((record) => {
          const index = plannedMoves.findIndex((move) => move.id === record.id);
          const formIndex = proposedMoves.findIndex(
            (plannedMove) => plannedMove.id === record.id
          );
          const existingData = plannedMoves[index];
          if (!existingData) {
            // Record may have been removed
            return;
          }
          updates.push({
            ...existingData,
            status: record.isValid ? 'valid' : 'invalid',
            isValidating: false,
            lastIntersectionError: record.intersection ?? undefined,
          });
          if (!record.isValid) {
            if (existingData.shipDate) {
              setError(`moves.${formIndex}.shipDate`, {
                type: 'custom',
                message: `This overlaps with a saved move for this asset`,
              });
            }
            if (existingData.deliveryDate) {
              setError(`moves.${formIndex}.deliveryDate`, {
                type: 'custom',
                message: `This overlaps with a saved move for this asset`,
              });
            }
          }
        });
        if (updates.length > 0) {
          updatePlannedMoveState(updates);
        }
        setRequestInProgress(false);
      }
      return Promise.resolve();
    },
    [
      plannedMoves,
      proposedMoves,
      setError,
      setRequestInProgress,
      updatePlannedMoveState,
    ]
  );

  /**
   * A callback function that checks for pending items. It ensures that no
   * additional checks are initiated while a request is already in progress.
   * Once the current check completes, it resets the `requestInProgress` state.
   *
   * Dependencies:
   * - `runCheckPendingItems`: Function to execute the pending items check.
   * - `setRequestInProgress`: Function to update the request progress state.
   * - `requestInProgress`: Boolean flag indicating if a request is currently in progress.
   */
  const checkPendingItems = useCallback(() => {
    if (requestInProgress) {
      // Skip callback check till the active request finishes
      return;
    }
    runCheckPendingItems(false).finally(() => {
      setRequestInProgress(false);
    });
  }, [runCheckPendingItems, setRequestInProgress, requestInProgress]);

  /**
   * Function to initiate the submission process.
   *
   * This function sets relevant state variables to indicate that a submission process has started.
   * It checks for any pending items before triggering the main submission action.
   * Based on the result or any error encountered during the process, it logs appropriate messages and updates the error state.
   * Regardless of success or failure, it ensures that the state variables for request progress and submission status are reset at the end.
   */
  const runSubmit = () => {
    setRequestInProgress(true);
    setErrorMessage(false);
    setIsSubmitting(true);
    runCheckPendingItems(true).then(() => {
      trigger()
        .then((result) => {
          if (result) {
            executeMultiMove(
              getValues().moves.map((move) => {
                return {
                  assetId: move.asset?.id ?? '',
                  originId: move.origin?.id ?? '',
                  destinationId: move.destination?.id ?? '',
                  shipAt: move.shipDate ?? undefined,
                  deliverAt: move.deliveryDate ?? undefined,
                };
              })
            )
              .then(
                (success) => {
                  if (success) {
                    setSubmissionComplete(true);
                    return;
                  }
                  setErrorMessage(
                    <SupportAlert message="Something went wrong with your request." />
                  );
                  // Trigger everything to revalidate
                  updatePlannedMoveState(
                    plannedMoves.map((move) => {
                      return {
                        ...move,
                        status: 'pending',
                        runAfter: new Date().getTime(),
                        altered: {
                          asset: true,
                          origin: true,
                          destination: true,
                          shipDate: true,
                          deliveryDate: true,
                        },
                      };
                    })
                  );
                },
                () => {
                  setErrorMessage(
                    <SupportAlert message="Something went wrong with your request." />
                  );
                }
              )
              .finally(() => {
                setRequestInProgress(false);
                setIsSubmitting(false);
              });
          } else {
            // Fuse everything so error states work correctly
            updatePlannedMoveState(
              plannedMoves.map((move) => {
                return {
                  ...move,
                  altered: {
                    asset: true,
                    origin: true,
                    destination: true,
                    shipDate: true,
                    deliveryDate: true,
                  },
                };
              })
            );
            setRequestInProgress(false);
            setIsSubmitting(false);
          }
        })
        .catch(() => {
          setRequestInProgress(false);
          setIsSubmitting(false);
        });
    });
  };

  // Handle submission
  const onSubmit = () => {
    if (isSubmitting) {
      return;
    }
    if (requestInProgress) {
      if (!queuedSubmit) {
        setQueuedSubmit(() => runSubmit);
      }
      return;
    }
    runSubmit();
  };

  /**
   * Create a polling effect that checks all pending items every second that are eligible to submit
   */
  useEffect(() => {
    const intervalId = setInterval(checkPendingItems, 1000); // Run every second
    return () => clearInterval(intervalId);
  }, [checkPendingItems]); // Only depends on the stable callback

  // Display grid widths
  const gridWidths = [2.4, 2.4, 2.4, 2.4, 2.4];

  const fieldsDisabled = isSubmitting || submissionComplete;
  return (
    <>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        Move assets{' '}
        <IconButton size={'small'} aria-label="close" onClick={props.onClose}>
          <Close fontSize={'small'} />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {errorMessage &&
          (isValidElement(errorMessage) ? (
            <Box sx={{ width: '60%', margin: '0 auto 20px auto' }}>
              {errorMessage}
            </Box>
          ) : (
            <Alert
              severity="error"
              sx={{ width: '60%', margin: '0 auto 20px auto' }}
            >
              Something went wrong with your request, please check your proposed
              moves and submit again.
            </Alert>
          ))}
        <Box>
          {/* Form Fields */}
          {fields.map((field, index) => {
            const rowId = getValues(`moves.${index}.id`);
            const rowState = getPlannedMoveValidationStatus(
              plannedMoves,
              rowId
            );

            let backgroundColor: string | null = null;
            let hoverColor: string | null = null;
            let borderColor: string | null = null;
            let tooltipText: string | undefined = undefined;
            const uninitializedIconColor = grey['600'];
            const pendingIconColor = blue['400'];
            const iconSx = {
              mt: 1,
            };
            let gutterIcon = (
              <LocalShippingOutlined
                htmlColor={uninitializedIconColor}
                sx={iconSx}
              />
            );
            switch (rowState as MultiMoveRowStatus) {
              case 'valid':
                backgroundColor = alpha(green['50'], 0.5);
                hoverColor = alpha(green['50'], 0.75);
                borderColor = theme.palette.success.main;
                gutterIcon = (
                  <CheckCircleOutlined color={'success'} sx={iconSx} />
                );
                tooltipText = 'This is a valid move';
                break;
              case 'invalid':
                {
                  backgroundColor = alpha(red['50'], 0.5);
                  hoverColor = alpha(red['50'], 0.75);
                  borderColor = theme.palette.error.main;
                  gutterIcon = <CancelOutlined color={'error'} sx={iconSx} />;
                  const rowDetails = getPlannedMoveDetails(plannedMoves, rowId);
                  if (rowDetails && rowDetails.lastIntersectionError) {
                    const start = DateTime.fromISO(
                      rowDetails.lastIntersectionError.start
                    )
                      .setZone(organization.timezone)
                      .toLocaleString({
                        dateStyle: 'medium',
                        timeStyle: 'medium',
                      });
                    const end = rowDetails.lastIntersectionError.end
                      ? DateTime.fromISO(rowDetails.lastIntersectionError.end)
                          .setZone(organization.timezone)
                          .toLocaleString({
                            dateStyle: 'medium',
                            timeStyle: 'medium',
                          })
                      : undefined;
                    if (end) {
                      tooltipText = `Asset is already in transit from ${start} to ${end}`;
                    } else {
                      tooltipText = `Asset is already in transit from ${start}`;
                    }
                  } else {
                    tooltipText = 'This move is not valid';
                  }
                }
                break;
              case 'pending':
                backgroundColor = alpha(blue['50'], 0.5);
                hoverColor = alpha(blue['50'], 0.75);
                borderColor = blue['300'];
                gutterIcon = (
                  <ScheduleOutlined htmlColor={pendingIconColor} sx={iconSx} />
                );
                tooltipText = 'Validating the details of this move';
                break;
            }

            if (isSubmitting) {
              backgroundColor = alpha(grey[300], 0.5);
              hoverColor = alpha(grey[300], 0.75);
              borderColor = alpha('#063DCB', 0.5);
              gutterIcon = (
                <ChangeCircleOutlined
                  sx={{
                    ...iconSx,
                    color: alpha(theme.palette.primary.main, 0.5),
                  }}
                />
              );
              tooltipText = 'Submission in progress';
            }

            if (submissionComplete) {
              backgroundColor = null;
              hoverColor = null;
              borderColor = '#063DCB';
              gutterIcon = (
                <AddTaskOutlined
                  sx={{
                    ...iconSx,
                    color: theme.palette.primary.main,
                  }}
                />
              );
              tooltipText = 'Move has been saved';
            }

            // Action.hover
            return (
              <Box
                key={field.id}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ mr: '.5rem' }}>
                  <ConditionalTooltip tooltipText={tooltipText}>
                    {gutterIcon}
                  </ConditionalTooltip>
                </Box>
                <Grid
                  container
                  sx={{
                    borderLeft: borderColor
                      ? `.2rem solid ${borderColor}`
                      : null,
                    backgroundColor: backgroundColor ? backgroundColor : null,
                    '&:hover': {
                      backgroundColor: hoverColor ? hoverColor : null,
                    },

                    mb: index === fields.length - 1 ? 0 : '2px',
                    py: 1.5, // Increased padding
                    height: '100%',
                    '& .MuiGrid-item': {
                      px: 0.5,
                    },
                    '& .MuiFormControl-root': {
                      width: '100%',
                    },
                  }}
                >
                  <Grid item xs={gridWidths[0]}>
                    <FormAssetSearch
                      label="Asset"
                      control={control}
                      disabled={fieldsDisabled}
                      filter={{ scrapped: false }}
                      name={`moves.${index}.asset`}
                    />
                  </Grid>
                  <Grid item xs={gridWidths[1]}>
                    <FormLocationSearch
                      label="Origin"
                      control={control}
                      disabled={fieldsDisabled}
                      name={`moves.${index}.origin`}
                      onCreate={handleCreateLocation}
                    />
                  </Grid>
                  <Grid item xs={gridWidths[2]}>
                    <FormLocationSearch
                      label="Destination"
                      control={control}
                      disabled={fieldsDisabled}
                      name={`moves.${index}.destination`}
                      onCreate={handleCreateLocation}
                    />
                  </Grid>
                  <Grid item xs={gridWidths[3]}>
                    <FormDateTimePickerV2
                      disableFuture
                      isClearable
                      disabled={fieldsDisabled}
                      name={`moves.${index}.shipDate`}
                      label="Ship date"
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={gridWidths[4]}>
                    <FormDateTimePickerV2
                      disableFuture
                      isClearable
                      disabled={fieldsDisabled}
                      name={`moves.${index}.deliveryDate`}
                      label="Delivery date"
                      control={control}
                    />
                  </Grid>
                </Grid>
                <Box sx={{ ml: '.5rem' }}>
                  <ConditionalTooltip
                    tooltipText={
                      fieldsDisabled
                        ? isSubmitting
                          ? 'Cannot delete while submitting'
                          : 'Record is already saved'
                        : undefined
                    }
                  >
                    {fieldsDisabled ? (
                      <IconButton
                        sx={{
                          minWidth: 'auto',
                          mb: 0.5,
                          p: 0,
                        }}
                      >
                        <Icon fontSize={'small'} />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => {
                          // Remove the form state
                          remove(index);
                        }}
                        sx={{
                          minWidth: 'auto',
                          mb: 0.5,
                          p: 0,
                          '&:hover': {
                            color: 'error.main',
                          },
                        }}
                      >
                        <DeleteRounded fontSize="small" />
                      </IconButton>
                    )}
                  </ConditionalTooltip>
                </Box>
              </Box>
            );
          })}
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between', // This creates space between left and right groups
        }}
      >
        <Box>
          {/* Left group */}
          <Button
            onClick={() => {
              append({
                id: crypto.randomUUID().toString(),
                asset: null,
                origin: null,
                destination: null,
                shipDate: null,
                deliveryDate: null,
              });
            }}
            disabled={fieldsDisabled}
            sx={{
              '&:hover': {
                backgroundColor: 'action.hover',
              },
            }}
          >
            Add move
          </Button>
        </Box>

        {submissionComplete && (
          <Alert severity={'success'} variant={'outlined'} sx={{ py: 0 }}>
            Success
          </Alert>
        )}
        <Box>
          {/* Right group */}
          <Button onClick={props.onClose}>
            {submissionComplete ? 'Close' : 'Cancel'}
          </Button>
          <Button
            onClick={onSubmit}
            disabled={fieldsDisabled}
            variant={'contained'}
            color={'secondary'}
          >
            Submit
          </Button>
        </Box>
      </DialogActions>
    </>
  );
}
