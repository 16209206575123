import { Organization } from '@/account/api/types.ts';
import { CRMDialog } from '@/account/components/crm-dialog.tsx';
import { useDisclosure } from '@/hooks/use-disclosure.ts';
import { CheckCircle, HighlightOff, Settings } from '@mui/icons-material';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';

interface CRMListProps {
  organization: Organization;
}

interface SettingsButtonProps {
  onOpen: () => void;
  text: string;
  buttonLabel: string;
}

function SettingsButton(props: SettingsButtonProps) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <HighlightOff color={'action'} fontSize={'small'} sx={{ mr: 1 }} />{' '}
      {props.text}
      <Button onClick={props.onOpen} variant={'contained'} sx={{ ml: 2 }}>
        {props.buttonLabel}
      </Button>
    </Box>
  );
}

export function CRMList(props: CRMListProps) {
  const integrationDialog = useDisclosure();
  const [defaultTab, setDefaultTab] = useState<number | undefined>(undefined);

  const isCRMConnected = props.organization.crmConfiguration !== null;
  const isCRMConfigured =
    props.organization.crmConfiguration !== null &&
    props.organization.crmConfiguration.configurationState.steps.PIPELINE
      .completed &&
    props.organization.crmConfiguration.configurationState.steps.COMPANY
      .completed &&
    props.organization.crmConfiguration.configurationState.steps.PROJECT
      .completed &&
    props.organization.crmConfiguration.configurationState.steps.PRODUCT
      .completed;
  const isCRMSyncing =
    props.organization.crmConfiguration !== null &&
    props.organization.crmConfiguration.configurationState.steps.SYNC.completed;
  return (
    <>
      <CRMDialog
        isOpen={integrationDialog.isOpen}
        organization={props.organization}
        onClose={integrationDialog.close}
        defaultTab={defaultTab}
      />
      <Table sx={{ width: '100%', paddingY: 4, paddingX: 2 }}>
        <TableHead>
          <TableRow>
            <TableCell width="20%">Name</TableCell>
            <TableCell width="25%">Connected</TableCell>
            <TableCell width="25%">Configured</TableCell>
            <TableCell width="25%">Syncing</TableCell>
            <TableCell width="5%" align={'right'}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <img
                src="https://files.hardfin.com/static/hubspot_logo.png"
                alt="HubSpot Logo"
                width="100px"
              />
            </TableCell>
            <TableCell>
              {!isCRMConnected ? (
                <SettingsButton
                  onOpen={integrationDialog.open}
                  buttonLabel="Connect"
                  text={'Not connected'}
                />
              ) : (
                <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                  <CheckCircle
                    color={'success'}
                    fontSize={'small'}
                    sx={{ mr: 1 }}
                  />{' '}
                  Connected
                </Typography>
              )}
            </TableCell>
            <TableCell>
              {!isCRMConnected ? (
                <Typography
                  variant={'body2'}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <HighlightOff
                    color={'action'}
                    fontSize={'small'}
                    sx={{ mr: 1 }}
                  />{' '}
                  Not configured
                </Typography>
              ) : !isCRMConfigured ? (
                <SettingsButton
                  onOpen={() => {
                    setDefaultTab(0);
                    integrationDialog.open();
                  }}
                  buttonLabel="Configure"
                  text="Not configured"
                />
              ) : (
                <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                  <CheckCircle
                    color={'success'}
                    fontSize={'small'}
                    sx={{ mr: 1 }}
                  />{' '}
                  Configured
                </Typography>
              )}
            </TableCell>
            <TableCell>
              {!isCRMConfigured ? (
                <Typography
                  variant={'body2'}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <HighlightOff
                    color={'action'}
                    fontSize={'small'}
                    sx={{ mr: 1 }}
                  />{' '}
                  Not syncing
                </Typography>
              ) : !isCRMSyncing ? (
                <SettingsButton
                  onOpen={() => {
                    setDefaultTab(4);
                    integrationDialog.open();
                  }}
                  buttonLabel="Set up sync"
                  text="Not enabled"
                />
              ) : (
                <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                  <CheckCircle
                    color={'success'}
                    fontSize={'small'}
                    sx={{ mr: 1 }}
                  />{' '}
                  {props.organization.crmConfiguration?.configurationState
                    .lastSyncedAt !== null
                    ? 'Last sync at ' +
                      props.organization.crmConfiguration?.configurationState.lastSyncedAt
                        .setZone(props.organization.timezone)
                        .toLocaleString({
                          dateStyle: 'medium',
                          timeStyle: 'short',
                        })
                    : 'not synced'}
                </Typography>
              )}
            </TableCell>
            <TableCell align={'right'}>
              {isCRMConnected && isCRMConfigured && isCRMSyncing && (
                <Settings onClick={integrationDialog.open} color={'action'} />
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
}
