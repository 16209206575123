import * as z from 'zod';
import {
  objectTypeSubTitleMap,
  objectTypeTitleMap,
} from '@/account/api/labels.ts';
import { CRMConfiguration, Organization } from '@/account/api/types.ts';
import { toggleHubspotSync } from '@/account/api/update-hubspot-pipeline-config.ts';
import { CRMConfigurationTab } from '@/account/components/crm-configuration-tab.tsx';
import { genericErrorHandler } from '@/api.ts';
import { FormDatePicker } from '@/components/form-date-picker.tsx';
import { dateTimeSchema } from '@/utilities/date-time-schema.ts';
import { zodResolver } from '@hookform/resolvers/zod';
import { InfoOutlined } from '@mui/icons-material';
import {
  Alert,
  Box,
  FormControl,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

const formSchema = z.object({
  enabled: z.boolean(),
  syncAfter: dateTimeSchema.optional().nullable(),
});
type FormData = z.infer<typeof formSchema>;

interface Props {
  orgKey: string;
  organization: Organization;
  crmConfiguration: CRMConfiguration;
  onClose: () => void;
}

export function CRMSyncConfiguration(props: Props) {
  const queryClient = useQueryClient();
  const { control, formState, handleSubmit, reset } = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      enabled: props.crmConfiguration.configurationState.steps.SYNC.completed,
      syncAfter: DateTime.now().toISODate(),
    },
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isFormDisabled, setFormDisabled] = useState(false);
  const [syncBoxDisabled, setSyncBoxDisabled] = useState(false);
  const [showLastSyncAt, setShowLastSyncAt] = useState<boolean | null>(null);
  const syncCompleted =
    props.crmConfiguration.configurationState.steps.SYNC.completed;
  const hasLastSync =
    props.crmConfiguration.configurationState.lastSyncedAt != null;
  useEffect(() => {
    // Effect runs on mount
    // This is to prevent "flickering" when the underlying props change after saving
    setShowLastSyncAt(syncCompleted && hasLastSync);
    return () => {
      // Cleanup effect runs on unmount
      setShowLastSyncAt(null);
      setErrorMessage('');
    };
  }, [setShowLastSyncAt, setErrorMessage, syncCompleted, hasLastSync]);

  const syncCanBeEnabled =
    props.crmConfiguration.configurationState.steps.PIPELINE.completed &&
    props.crmConfiguration.configurationState.steps.PRODUCT.completed &&
    props.crmConfiguration.configurationState.steps.PROJECT.completed &&
    props.crmConfiguration.configurationState.steps.COMPANY.completed;

  const toggleHubspotSyncMutation = useMutation({
    mutationFn: (data: FormData) => {
      return toggleHubspotSync(
        data.enabled,
        showLastSyncAt === false
          ? data.syncAfter
            ? data.syncAfter.toISODate()
            : null
          : null
      );
    },
    onSuccess: (_, data) => {
      // Cause the organization to reload
      queryClient.invalidateQueries({
        queryKey: ['organization', props.orgKey],
      });
      setSyncBoxDisabled(true);
      reset({
        enabled: data.enabled,
        syncAfter: data.syncAfter,
      });
    },
    onError: genericErrorHandler(setErrorMessage),
  });
  const handleValidSubmission: SubmitHandler<FormData> = (data) => {
    setFormDisabled(true);
    setTimeout(() => {
      setFormDisabled(false);
    }, 16.6666 * 10); // Delay for 10 frames at 60hz to help with flicker
    toggleHubspotSyncMutation.mutate(data);
  };
  return (
    <CRMConfigurationTab
      errorMessage={errorMessage}
      isDirty={formState.isDirty}
      onClose={props.onClose}
      onSubmit={handleSubmit(handleValidSubmission)}
      isDisabled={isFormDisabled}
      tabBody={
        <React.Fragment>
          <Typography
            variant="h6"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            {objectTypeTitleMap['SYNC']}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            align={'left'}
            marginTop={1}
            marginBottom={1}
          >
            {objectTypeSubTitleMap['SYNC']}
          </Typography>
          {!syncCanBeEnabled && (
            <Alert severity={'warning'} sx={{ gridColumn: '1 / -1' }}>
              Property mapping is required before this can be enabled
            </Alert>
          )}
          {showLastSyncAt === false && (
            <Box
              sx={{
                display: 'grid',
                flexDirection: 'column',
                gap: 1,
                gridTemplateColumns: '2fr 1fr',
                marginTop: 2,
                marginBottom: 1,
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{ display: 'flex', justifyContent: 'flex-start' }}
              >
                Sync deals modified after{' '}
                <Tooltip
                  arrow
                  placement="top"
                  title="Hardfin will import any deal modified on this date or later"
                >
                  <InfoOutlined
                    fontSize="small"
                    color="primary"
                    sx={{ ml: 0.5 }}
                  />
                </Tooltip>
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <FormDatePicker
                  name={'syncAfter'}
                  control={control}
                  isDisabled={
                    !syncCanBeEnabled || isFormDisabled || syncBoxDisabled
                  }
                  placeholderText={'Date'}
                  shortcuts={[
                    {
                      label: 'Today',
                      getValue: () => DateTime.now(),
                    },
                    {
                      label: '30 Days',
                      getValue: () => {
                        return DateTime.now().minus({ days: 30 });
                      },
                    },
                    {
                      label: '90 Days',
                      getValue: () => {
                        return DateTime.now().minus({ days: 90 });
                      },
                    },
                    {
                      label: '365 Days',
                      getValue: () => {
                        return DateTime.now().minus({ days: 365 });
                      },
                    },
                  ]}
                />
              </Box>
            </Box>
          )}
          {showLastSyncAt === null && <Box sx={{ marginTop: 2 }}></Box>}
          <Controller
            name={'enabled'}
            control={control}
            render={({ field }) => (
              <FormControl sx={{ width: '100%' }}>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '3fr 1fr',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    marginX: 'auto',
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ display: 'flex', justifyContent: 'flex-start' }}
                  >
                    Enable automatic sync{' '}
                    <Tooltip
                      arrow
                      placement="top"
                      title="Start importing deals now and on an ongoing basis"
                    >
                      <InfoOutlined
                        fontSize="small"
                        color="primary"
                        sx={{ ml: 0.5 }}
                      />
                    </Tooltip>
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Switch
                      size="medium"
                      {...field}
                      checked={field.value}
                      disabled={!syncCanBeEnabled}
                    />
                  </Box>
                </Box>
              </FormControl>
            )}
          />
          {showLastSyncAt === true &&
            props.crmConfiguration.configurationState.lastSyncedAt && (
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  marginTop: 1,
                }}
              >
                <Typography
                  variant="body2"
                  color={'textSecondary'}
                  sx={{ paddingRight: 2, fontStyle: 'italic' }}
                >
                  Last sync at{' '}
                  {props.crmConfiguration.configurationState.lastSyncedAt
                    .setZone(props.organization.timezone)
                    .toLocaleString({
                      dateStyle: 'medium',
                      timeStyle: 'short',
                    })}
                </Typography>
              </Box>
            )}
        </React.Fragment>
      }
    />
  );
}
