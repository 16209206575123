const formatter = Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'usd',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

/**
 * Formats a project's total contract value as a currency. If the value is null
 * or is not a number, this function returns 'Not set' instead.
 *
 * @param value - The total contract value of a project.
 */
export function formatValueAsCurrency(value: string | null) {
  if (value === null) {
    return 'Not set';
  }

  const asNumber = Number(value);

  if (Number.isNaN(asNumber)) {
    return 'Not set';
  }

  return formatter.format(Number(value));
}
