import { HubSpotLogo } from '@/icons/hub-spot-logo.tsx';
import { Box, Tooltip } from '@mui/material';
import { PropsWithChildren } from 'react';

interface CRMStatus {
  crmType: string;
  crmLink: string | null;
}

interface Props extends PropsWithChildren {
  crmStatus: CRMStatus;
  width?: number | string;
  iconOpacity?: number;
  notLinkedTooltip: string;
}

export function CRMStatus(props: Props) {
  const width = props.width ?? '24px';
  switch (props.crmStatus.crmType) {
    case 'NONE':
      return null;
    case 'HUBSPOT':
      if (props.crmStatus.crmLink) {
        if (props.crmStatus.crmLink.startsWith('#')) {
          return (
            <Tooltip
              title={props.crmStatus.crmLink.substring(1)}
              placement="bottom"
              arrow
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: width,
                  opacity: props.iconOpacity ? props.iconOpacity : 0.65,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    opacity: props.iconOpacity ? props.iconOpacity : 1,
                  }}
                >
                  <HubSpotLogo width={width} />
                </Box>
                {props.children}
              </Box>
            </Tooltip>
          );
        }
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: width,
            }}
          >
            <a
              href={props.crmStatus.crmLink}
              target="_blank"
              rel="noreferrer"
              style={{ lineHeight: 0 }}
            >
              <Box
                sx={{
                  display: 'flex',
                  opacity: props.iconOpacity ? props.iconOpacity : 1,
                }}
              >
                <HubSpotLogo width={width} />
              </Box>
              {props.children}
            </a>
          </Box>
        );
      }
      return (
        <Tooltip title={props.notLinkedTooltip} placement="bottom" arrow>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: width,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                opacity: props.iconOpacity ? props.iconOpacity : 1,
              }}
            >
              <HubSpotLogo color={'action'} width={width} />
            </Box>
            {props.children}
          </Box>
        </Tooltip>
      );
  }
}
