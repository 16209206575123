import { api, successResponseSchema } from '@/api.ts';

/**
 * Represents a request to delete a project.
 * This includes the key of the project to identify it and
 * a reason explaining why the project is being deleted.
 */
export interface HubspotResyncProject {
  projectId: string;
}

/**
 * Deletes an existing project.
 *
 * @param props contains a list of properties
 */
export async function hubSpotResyncProject(props: HubspotResyncProject) {
  const path = `/v1/crm/hubspot/resync`;
  const response = await api.post(path, {
    entityType: 'PROJECT',
    entityId: props.projectId,
  });

  return successResponseSchema.parseAsync(response.data);
}
