import { AssetMultiMove } from '@/asset/components/asset-multi-move.tsx';
import { LoadingIndicator } from '@/components/navigation-layout.tsx';
import { SupportErrorBoundary } from '@/components/support-error-boundary.tsx';
import { Dialog } from '@mui/material';
import { Suspense } from 'react';

interface Props {
  /**
   * Controls the visibility of the dialog.
   * When true, the dialog is displayed to the user.
   * The dialog can be closed through the close button,
   * clicking outside, or successful asset creation.
   */
  isOpen: boolean;

  /**
   * Callback function for dialog closure.
   * Called when the user cancels or completes asset creation.
   * The parent component should update its state to hide the dialog.
   */
  onClose: () => void;
}

export function AssetMultiMoveDialog(props: Props) {
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={props.isOpen}
      onClose={(_event, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        props.onClose();
      }}
    >
      <Suspense fallback={<LoadingIndicator />}>
        <SupportErrorBoundary message="An error occurred while planning an asset move.">
          <AssetMultiMove onClose={props.onClose} />
        </SupportErrorBoundary>
      </Suspense>
    </Dialog>
  );
}
