import { Toast, ToastContext, ToastOptions } from '@/contexts/toast-context.ts';
import { Alert, Fade, Snackbar, SnackbarCloseReason } from '@mui/material';
import React, { PropsWithChildren, useMemo, useState } from 'react';

/**
 * A provider component for managing and displaying toast notifications.
 * It uses context to handle toast-related operations such as adding and removing toasts.
 *
 * @example
 * ```tsx
 * const { showToast } = useToast();
 *
 * const handleShowToast = () => {
 *   showToast({
 *     severity: "success",  // or "error", "warning", "info"
 *     element: <span>Toast message goes here!</span>,
 *     duration: 3000,      // Auto-hide after 3 seconds
 *     sx: { backgroundColor: "green" }  // Custom styles for the toast
 *   });
 * };
 *
 * return (
 *   <ToastProvider>
 *     <div>
 *       <button onClick={handleShowToast}>Show Toast</button>
 *     </div>
 *   </ToastProvider>
 * );
 * ```
 *
 * @param props - The props passed to the ToastProvider, including children components.
 * @param {PropsWithChildren} props.children - The child components to be wrapped by the provider.
 * @returns {JSX.Element} The ToastProvider component wrapping its children and managing toast notifications.
 */
export function ToastProvider(props: PropsWithChildren) {
  const [toasts, setToasts] = useState<Toast[]>([]);
  // Track open state for all toasts in a single state object
  const [openToasts, setOpenToasts] = useState<Record<string, boolean>>({});
  const actions = useMemo(
    () => ({
      showToast: (options: ToastOptions) => {
        const id = Date.now().toString();
        setToasts((prevToasts) => [...prevToasts, { id, ...options }]);
        // Set initial open state for new toast
        setOpenToasts((prev) => ({ ...prev, [id]: true }));
      },
      removeToast: (id: string) => {
        setToasts((prevToasts) =>
          prevToasts.filter((toast) => toast.id !== id)
        );
        // Clean up open state when toast is removed
        setOpenToasts((prev) => {
          const newState = { ...prev };
          delete newState[id];
          return newState;
        });
      },
    }),
    []
  );

  const handleClose =
    (id: string) =>
    (_event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpenToasts((prev) => ({ ...prev, [id]: false }));
    };

  return (
    <ToastContext.Provider value={actions}>
      {props.children}
      {toasts.map((toast, index) => {
        const toastStyles = {
          minWidth: '300px',
          ...toast.sx,
        };

        return (
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={openToasts[toast.id]}
            onClose={handleClose(toast.id)}
            key={toast.id}
            autoHideDuration={toast.duration}
            TransitionProps={{
              onExited: () => actions.removeToast(toast.id),
              timeout: 750,
            }}
            TransitionComponent={Fade}
            sx={{
              marginBottom: `${20 + index * 70}px`,
              marginRight: '20px',
            }}
          >
            <Alert
              icon={false}
              {...toast}
              onClose={handleClose(toast.id)}
              variant={'standard'}
              sx={toastStyles}
            >
              {toast.element}
            </Alert>
          </Snackbar>
        );
      })}
    </ToastContext.Provider>
  );
}
