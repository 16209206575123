import * as z from 'zod';
import {
  hubspotDealPropertyQueryKey,
  updateHubspotPropertyMapping,
} from '@/account/api/hubspot-property-mapping';
import {
  objectTypeLabelMap,
  objectTypeSubTitleMap,
  objectTypeTitleMap,
} from '@/account/api/labels.ts';
import {
  HubspotConfigurationPage,
  HubspotObjectType,
  HubspotPropertyConfiguration,
  HubspotPropertyRecord,
  hubspotPropertyConfigurationResponseSchema,
} from '@/account/api/types.ts';
import { CRMConfigurationTab } from '@/account/components/crm-configuration-tab.tsx';
import { CRMPropertySelector } from '@/account/components/crm-property-selector';
import { CRMResyncPropertiesHubspot } from '@/account/components/crm-resync-hubspot';
import { api, genericErrorHandler } from '@/api.ts';
import { LoadingIndicator } from '@/components/navigation-layout';
import { ScrollIndicator } from '@/components/scroll-indicator';
import { useScrollIndicator } from '@/hooks/use-scroll-indicator';
import { HubSpotLogo } from '@/icons/hub-spot-logo.tsx';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  ArrowBack,
  ChangeCircle,
  CheckCircleOutlined,
  CircleOutlined,
} from '@mui/icons-material';
import { Box, Divider, Typography } from '@mui/material';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';

interface Props {
  pageID: HubspotConfigurationPage;
  queryKey: readonly string[];
  orgKey: string;
  onClose: () => void;
}

const objectTypeHeaderMap: Record<HubspotObjectType, string> = {
  DEAL: 'Deal',
  COMPANY: 'Company',
  LINE_ITEM: 'Deal line item',
};

const outputSchema = z.object({
  mappings: z.array(
    z.discriminatedUnion('optional', [
      // When optional is false, require propertyValue
      z.object({
        propertyId: z.string(),
        objectType: z.string(),
        optional: z.literal(false),
        propertyValue: z
          .string({ required_error: 'A valid HubSpot field is required' })
          .min(1, 'A valid HubSpot field is required'),
      }),
      // When optional is true, make propertyValue optional
      z.object({
        propertyId: z.string(),
        objectType: z.string(),
        optional: z.literal(true),
        propertyValue: z.string().optional(),
      }),
    ])
  ),
});
type OutputData = z.infer<typeof outputSchema>;

function CRMPropertyMapping(props: Props) {
  const [errorMessage, setErrorMessage] = useState('');
  const [isRefreshing, notifyRefresh] = useState(false);
  const [isFormDisabled, setFormDisabled] = useState(false);

  const { formState, reset, handleSubmit, control, clearErrors, setValue } =
    useForm<OutputData>({
      resolver: zodResolver(outputSchema),
      defaultValues: {
        mappings: [],
      },
    });

  const queryClient = useQueryClient();
  const setComponentDefaults = useCallback(
    (data: HubspotPropertyConfiguration) => {
      // SetDefaults is used to track the default values for each field if they need to be set
      const setDefaults: Record<number, string> = {};
      // Field index is used to track the order of the fields, it tracks against the entire record set not just the current record
      let fieldIndex = 0;
      const defaults = {
        mappings: Object.entries(data.mappings).flatMap(
          ([objectType, mappings]) =>
            mappings.map((mapping) => {
              const tainted = Boolean(mapping.defaultValue && !mapping.wasSet);
              if (tainted) {
                setDefaults[fieldIndex] = mapping.defaultValue ?? '';
              }
              fieldIndex++;
              return {
                propertyId: mapping.propertyId,
                objectType: objectType as HubspotObjectType,
                propertyValue: mapping.value ?? '', // If a default value was set, it will be set later on if the field is tainted
                optional: mapping.optional,
              };
            })
        ),
      };
      // Reset the form state to the defaults, tainted fields will be set to dirty in the next step
      reset(defaults);
      // Loop through the setDefaults and update the form state for each field
      for (const [fieldIndex, defaultValue] of Object.entries(setDefaults)) {
        const idx = Number(fieldIndex);
        // This is needed to ensure the form state is set to dirty when the defaults are set for each field
        // DO NOT USE useFieldArray, it can't set isDirty on the fields.
        // When this component double mounts, it improperly detects the state without flagging id dirty.
        // The entire point of this subroutine is to taint the frame.
        setValue(
          `mappings.${idx}`,
          {
            propertyId: defaults.mappings[idx].propertyId,
            objectType: defaults.mappings[idx].objectType,
            propertyValue: defaultValue,
            optional: defaults.mappings[idx].optional,
          },
          {
            shouldDirty: true,
            shouldValidate: true,
          }
        );
      }
      setErrorMessage('');
    },
    [reset, setErrorMessage, setValue]
  );

  const { data, status, fetchStatus } = useSuspenseQuery({
    queryKey: props.queryKey,
    refetchOnMount: 'always', // Always refetch when mounted
    refetchOnWindowFocus: false, // Do-not refetch when window is refocused,
    queryFn: async () => {
      const response = await api.get(
        `/v1/crm/hubspot/properties/${props.pageID.toLowerCase()}`,
        {}
      );
      const result =
        await hubspotPropertyConfigurationResponseSchema.parseAsync(
          response.data
        );

      return result.data;
    },
  });

  useEffect(() => {
    if (data) {
      // When data changes, update the form defaults and enable setting values to their default values
      setComponentDefaults(data);
    }
  }, [data, setComponentDefaults]);

  useEffect(() => {
    return () => {
      // Clear the query when we unount
      queryClient.invalidateQueries({
        queryKey: props.queryKey,
      });
      reset();
      setErrorMessage('');
      setFormDisabled(false);
    };
  }, [queryClient, reset, props.queryKey, setErrorMessage, setFormDisabled]);

  const updateHubspotPropertyMappingMutation = useMutation({
    mutationFn: (parameters: OutputData) =>
      updateHubspotPropertyMapping(
        props.pageID,
        parameters.mappings.map((mapping) => ({
          propertyId: mapping.propertyId,
          objectType: mapping.objectType,
          value: mapping.propertyValue
            ? mapping.propertyValue.trim() || null
            : null,
        }))
      ),
    onSuccess: (data) => {
      queryClient.setQueryData(hubspotDealPropertyQueryKey, () => data.data);
      // Set the configuration state from query metadata
      // Cause the organization to reload
      queryClient
        .invalidateQueries({
          queryKey: ['organization', props.orgKey],
        })
        .then(() => setComponentDefaults(data.data));
    },
    onError: genericErrorHandler(setErrorMessage),
  });

  const onSubmitHandler = (formData: OutputData) => {
    // Handle the form submission
    setFormDisabled(true);
    setTimeout(() => {
      setFormDisabled(false);
    }, 16.6666 * 10); // Delay for 8 frames at 60hz to help with flicker
    updateHubspotPropertyMappingMutation.mutate(formData);
  };

  // Disable the ability to change data for any of the reasons
  const fieldsDisabled =
    isFormDisabled || // Is the form timeout disabled
    isRefreshing || // Resync was clicked
    status == 'error' || // The query state is in error
    formState.isSubmitting || // The form is submitting
    // updateHubspotPipelineConfigMutation.isPending || // Update mutation is running
    !(fetchStatus === 'idle'); // Fetch is active

  // Create a map of the properties by name
  const propertyMap = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(data.properties).map(([domain, props]) => [
          domain,
          new Map(props.map((p) => [p.name, p])),
        ])
      ) as Record<HubspotObjectType, Map<string, HubspotPropertyRecord>>,
    [data.properties]
  );

  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollIndicator = useScrollIndicator(scrollRef);
  /**
   * Handles click on scroll indicator.
   * Scrolls timeline to bottom smoothly.
   */
  const handleClickScroll = () => {
    if (scrollRef.current === null) {
      return;
    }

    scrollRef.current.scrollTo({
      top: scrollRef.current.scrollHeight,
      behavior: 'smooth',
    });

    scrollIndicator.hide();
  };

  // Field index is used to track the order of the fields when rendered, pages have record sets and each record set has a set of fields.
  // The form field index is global to the entire record set not just the current record.
  let fieldIndex = 0;
  return (
    <CRMConfigurationTab
      onClose={props.onClose}
      isDirty={formState.isDirty}
      errorMessage={errorMessage}
      onSubmit={handleSubmit(onSubmitHandler)}
      isDisabled={fieldsDisabled}
      tabBody={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
              alignContent: 'center',
            }}
          >
            <Typography
              variant="h6"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              {objectTypeTitleMap[props.pageID]}
            </Typography>
            <Box sx={{ marginLeft: 'auto', justifyContent: 'flex-end' }}>
              <CRMResyncPropertiesHubspot
                queryKey={props.queryKey}
                propertyId={props.pageID}
                setErrorMessage={setErrorMessage}
                notifyRefresh={notifyRefresh}
                onRefresh={setComponentDefaults}
              />
            </Box>
          </Box>
          <Typography
            variant="body2"
            color="textSecondary"
            align={'left'}
            marginTop={1}
            marginBottom={1}
          >
            {objectTypeSubTitleMap[props.pageID]}
          </Typography>
          <Box
            ref={scrollRef}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '100%',
              overflow: 'auto',
              margin: '1rem auto 0 auto',
              position: 'relative',
              pb: 2,
            }}
          >
            <ScrollIndicator
              label="More"
              isVisible={scrollIndicator.isVisible}
              onClick={handleClickScroll}
            />
            {fetchStatus !== 'idle' || isRefreshing ? (
              <LoadingIndicator sx={{ margin: 'auto' }} />
            ) : (
              <React.Fragment>
                {/* Table content */}
                <Box
                  sx={{
                    display: 'grid',
                    flexDirection: 'column',
                    gap: 1.5,
                    gridTemplateColumns: '.6fr .2fr 1fr',
                    alignItems: 'center',
                  }}
                >
                  {Object.entries(data.mappings).map(([ot, mappings]) => {
                    const objectType = ot as HubspotObjectType;
                    return (
                      <React.Fragment key={objectType}>
                        <Box
                          sx={{
                            gridColumn: '1 / -1',
                            display: 'grid',
                            gridTemplateColumns: '.6fr .2fr 1fr',
                            alignItems: 'center',
                            position: 'sticky',
                            gap: 1.5,
                            top: 0,
                            zIndex: 20,
                            bgcolor: 'background.paper',
                            pb: 1,
                            borderBottom: 1,
                            borderColor: 'divider',
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{ display: 'flex', alignItems: 'center' }}
                          >
                            <img
                              alt={'Hardfin logo'}
                              src={
                                'https://files.hardfin.com/static/hardfin-icon.png'
                              }
                              style={{
                                marginRight: '.5rem',
                              }}
                              height={'20px'}
                            />
                            {objectTypeLabelMap[props.pageID]}{' '}
                          </Typography>
                          <Box />
                          <Typography
                            variant="subtitle1"
                            sx={{ display: 'flex', alignItems: 'center' }}
                          >
                            <HubSpotLogo
                              style={{
                                marginRight: '.5rem',
                                height: '20px',
                              }}
                            />
                            {objectTypeHeaderMap[objectType]}
                          </Typography>
                        </Box>
                        {mappings.map((mapping, categoryIndex) => {
                          const fieldOffset = fieldIndex;
                          fieldIndex++;
                          return (
                            <React.Fragment key={mapping.propertyId}>
                              <Typography
                                variant="subtitle2"
                                color={
                                  mapping.optional
                                    ? 'text.secondary'
                                    : 'text.primary'
                                }
                              >
                                {mapping.propertyLabel}
                                {mapping.optional && ' (optional)'}
                              </Typography>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <ArrowBack
                                  color={
                                    mapping.optional ? 'secondary' : 'action'
                                  }
                                />
                              </Box>
                              <Controller
                                name={`mappings.${fieldOffset}`}
                                control={control}
                                defaultValue={{
                                  propertyId: mapping.propertyId,
                                  objectType: objectType,
                                  propertyValue: '',
                                  optional: mapping.optional,
                                }}
                                render={({ field, fieldState }) => {
                                  return (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <CRMPropertySelector
                                        placeholder="Select a HubSpot field"
                                        disabled={fieldsDisabled}
                                        options={
                                          data.properties[objectType] ?? []
                                        }
                                        sx={{
                                          width: '100%',
                                          mr: 1,
                                        }}
                                        value={
                                          (field.value.propertyValue &&
                                            propertyMap[objectType].get(
                                              field.value.propertyValue
                                            )) ||
                                          null
                                        }
                                        onChange={(_, value) => {
                                          field.onChange({
                                            propertyId: mapping.propertyId,
                                            objectType: objectType,
                                            propertyValue: value?.name ?? '',
                                            optional: Boolean(mapping.optional),
                                          });
                                          clearErrors(
                                            `mappings.${fieldOffset}`
                                          );
                                        }}
                                        error={Boolean(fieldState.error)}
                                        helperText={
                                          fieldState.error &&
                                          'This field is required'
                                        }
                                      />
                                      {fieldState.isDirty ? (
                                        <ChangeCircle
                                          sx={{
                                            color: 'info.main',
                                            mr: 1,
                                          }}
                                          fontSize="small"
                                        />
                                      ) : field.value.propertyValue !==
                                          undefined &&
                                        field.value.propertyValue !== '' ? (
                                        <CheckCircleOutlined
                                          sx={{
                                            color: 'info.main',
                                            mr: 1,
                                          }}
                                          fontSize="small"
                                        />
                                      ) : (
                                        <CircleOutlined
                                          sx={{
                                            color: 'info.main',
                                            mr: 1,
                                          }}
                                          fontSize="small"
                                        />
                                      )}
                                    </Box>
                                  );
                                }}
                              />

                              {/* Add divider if not the last item */}
                              {categoryIndex < mappings.length - 1 && (
                                <React.Fragment>
                                  <Divider sx={{ gridColumn: '1 / -1' }} />
                                </React.Fragment>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </React.Fragment>
                    );
                  })}
                </Box>
              </React.Fragment>
            )}
          </Box>
        </Box>
      }
    />
  );
}

export default CRMPropertyMapping;
