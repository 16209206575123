/**
 * Normalizes SKU value by converting empty strings to null
 * Used in both create and edit product dialogs to ensure consistent SKU handling
 *
 * @param sku - The SKU string to normalize
 * @returns null if SKU is empty or whitespace, otherwise returns the original SKU
 */
export const nullifyEmptySku = (sku: string | null): string | null => {
  return sku?.trim() === '' ? null : sku;
};
