import { HubspotEntityReferenceObject } from '@/audit/api/types.ts';
import { AuditPropertyValue } from '@/audit/components/audit-property-value.tsx';
import { ArrowForwardRounded } from '@mui/icons-material';
import { Typography } from '@mui/material';

interface Props {
  property: HubspotEntityReferenceObject;
}

export function AuditHubspotEntityReference(props: Props) {
  const newValue = props.property.newValue?.label ?? null;

  const oldValue = props.property.oldValue?.label ?? null;

  return (
    <Typography display="flex" variant="caption" alignItems="center">
      <Typography component="span" variant="inherit" fontWeight="bold">
        {props.property.name}:&nbsp;
      </Typography>
      <AuditPropertyValue value={oldValue} />
      <ArrowForwardRounded fontSize="inherit" sx={{ marginX: 1 }} />
      <AuditPropertyValue value={newValue} />
    </Typography>
  );
}
