import { SupportErrorBoundary } from '@/components/support-error-boundary.tsx';
import { CloseRounded } from '@mui/icons-material';
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactElement, Suspense } from 'react';

interface AuditDrawerProps {
  auditLog: ReactElement;
  title?: string;
  disclosure: {
    isOpen: boolean;
    open: () => void;
    close: () => void;
  };
}

export function AuditDrawer(props: AuditDrawerProps) {
  const theme = useTheme();
  return (
    <Drawer
      anchor="right"
      open={props.disclosure.isOpen}
      onClose={props.disclosure.close}
      PaperProps={{ sx: { maxWidth: '35rem', minWidth: '25rem' } }}
      sx={{ zIndex: theme.zIndex.modal }}
    >
      <Stack
        spacing={1}
        padding={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="subtitle1">
          {props.title ? props.title : 'Audit log'}
        </Typography>
        <IconButton size="small" onClick={props.disclosure.close}>
          <CloseRounded fontSize="small" />
        </IconButton>
      </Stack>
      <Divider />
      <Box padding={2}>
        <SupportErrorBoundary message="An error occurred while loading the audit log.">
          <Suspense>{props.auditLog}</Suspense>
        </SupportErrorBoundary>
      </Box>
    </Drawer>
  );
}
