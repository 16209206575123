import { HubspotConfigurationPage } from '@/account/api/types.ts';

export const objectTypeTitleMap: Record<HubspotConfigurationPage, string> = {
  PIPELINE: 'Sync trigger',
  PROJECT: 'Project mapping',
  COMPANY: 'Customer mapping',
  PRODUCT: 'Line item mapping',
  SYNC: 'Enable sync',
};

export const objectTypeSubTitleMap: Record<HubspotConfigurationPage, string> = {
  PIPELINE:
    'Sync your HubSpot deal data into a new Hardfin project automatically\n' +
    '            when deals reach a certain stage',
  PROJECT: 'Map your Hardfin projects from your HubSpot deals',
  COMPANY: 'Map your Hardfin customers from your HubSpot companies',
  PRODUCT: 'Map your Hardfin line items from your HubSpot line items',
  SYNC: 'Enable or disable syncing of your Hardfin data to HubSpot',
};

export const objectTypeLabelMap: Record<HubspotConfigurationPage, string> = {
  PIPELINE: 'Pipeline',
  PROJECT: 'Project',
  COMPANY: 'Customer',
  PRODUCT: 'Project line item',
  SYNC: 'Sync',
};
