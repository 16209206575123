import {
  Box,
  BoxProps,
  InternalStandardProps as StandardProps,
  Tooltip,
} from '@mui/material';
import { PropsWithChildren } from 'react';

interface ConditionalTooltipProps
  extends PropsWithChildren,
    StandardProps<BoxProps> {
  tooltipText?: string;
}

/**
 * A component that conditionally wraps its children with a tooltip if a tooltip text is provided.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.tooltipText - The text to display in the tooltip. If not provided, the children are rendered without a tooltip.
 * @param {React.ReactNode} props.children - The child elements to be rendered, optionally wrapped with a tooltip.
 * @param {...Object} rest - Additional props to be applied to the container element, when null this creates a new <Box /> wrapper.
 *
 * @return {React.ReactNode} Returns the child elements wrapped in a tooltip if tooltipText is provided, otherwise returns the child elements directly.
 */
export function ConditionalTooltip(props: ConditionalTooltipProps) {
  const { tooltipText, children, ...rest } = props;
  if (!tooltipText) {
    return <Box {...rest}>{children}</Box>;
  }
  return (
    <Tooltip title={tooltipText}>
      <Box {...rest}>{children}</Box>
    </Tooltip>
  );
}
