import { LoadingIndicator } from '@/components/navigation-layout';
import { SupportErrorBoundary } from '@/components/support-error-boundary';
import { Alert, Box, Button } from '@mui/material';
import { Suspense } from 'react';

interface FormLayoutProps {
  tabBody: React.ReactNode;
  onClose: () => void;
  onSubmit: (e: React.FormEvent) => void;
  isDisabled?: boolean;
  errorMessage: string;
  isDirty: boolean;
}

export function CRMConfigurationTab({
  tabBody,
  onClose,
  onSubmit,
  isDisabled,
  errorMessage,
  isDirty,
}: FormLayoutProps) {
  return (
    <Suspense fallback={<LoadingIndicator />}>
      <Box
        component="form"
        onSubmit={onSubmit}
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <SupportErrorBoundary message="Something went wrong">
          {errorMessage !== '' && (
            <Box sx={{ my: 2 }}>
              <Alert severity="error">{errorMessage}</Alert>
            </Box>
          )}

          <Box
            sx={{
              flex: '1 1 auto',
              minHeight: 0, // Required for Firefox
              overflow: 'auto',
            }}
          >
            {tabBody}
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 1,
              padding: '20px 0',
              bgcolor: 'background.paper',
              borderTop: 1,
              borderColor: 'divider',
              flex: '0 0 auto', // Prevent footer from shrinking or growing
            }}
          >
            <Button onClick={onClose}>{isDirty ? 'Cancel' : 'Close'}</Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={isDisabled || !isDirty}
            >
              Save
            </Button>
          </Box>
        </SupportErrorBoundary>
      </Box>
    </Suspense>
  );
}
