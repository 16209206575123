import { PlanMultiMoveIntersection } from '@/asset/api/multi-move.ts';
import { dateTimeSchemaV2 } from '@/utilities/date-time-schema.ts';
import { FieldError, UseFormSetError } from 'react-hook-form';
import { z } from 'zod';

const assetSchema = z.object({
  id: z.string(),
  key: z.string(),
  serial: z.string(),
});

const locationSchema = z.object({
  id: z.string(),
  key: z.string(),
  name: z.string(),
});

export const formItemSchema = z
  .object({
    id: z.string(),
    asset: assetSchema.nullable(),
    origin: locationSchema.nullable(),
    destination: locationSchema.nullable(),
    shipDate: dateTimeSchemaV2.nullable(),
    deliveryDate: dateTimeSchemaV2.nullable(),
  })
  .superRefine((move, context) => {
    if (!move.asset) {
      context.addIssue({
        code: 'custom',
        path: ['asset'],
        message: 'Asset is required',
      });
    }
    if (move.origin === null) {
      context.addIssue({
        code: 'custom',
        path: ['origin'],
        message: 'Origin is required',
      });
    }
    if (move.destination === null) {
      context.addIssue({
        code: 'custom',
        path: ['destination'],
        message: 'Destination is required',
      });
    }
    if (!move.shipDate && !move.deliveryDate) {
      context.addIssue({
        code: 'custom',
        path: ['shipDate'],
        message: 'Ship or delivery date is required',
      });
      context.addIssue({
        code: 'custom',
        path: ['deliveryDate'],
        message: 'Delivery or ship date is required',
      });
    }
    if (
      move.shipDate &&
      move.deliveryDate &&
      move.shipDate > move.deliveryDate
    ) {
      context.addIssue({
        code: 'custom',
        path: ['shipDate'],
        message: 'Ship date cannot be after the delivery date',
      });
    }
  });

export const multiMoveFormSchema = z.object({
  moves: z.array(formItemSchema),
});
export type MultiMoveFormData = z.infer<typeof multiMoveFormSchema>;
export type MultiMoveFormItemData = z.infer<typeof formItemSchema>;

export const multiMoveFormKeys = [
  { id: 'asset' as MultiMoveFormKey, label: 'Asset', required: true },
  { id: 'origin' as MultiMoveFormKey, label: 'Origin', required: true },
  {
    id: 'destination' as MultiMoveFormKey,
    label: 'Destination',
    required: false,
  },
  { id: 'shipDate' as MultiMoveFormKey, label: 'Ship Date', required: true },
  {
    id: 'deliveryDate' as MultiMoveFormKey,
    label: 'Delivery Date',
    required: false,
  },
];

const overlapErrorMessage = 'This overlaps with another move for this asset';

/**
 * Checks for overlapping date intervals in a set of proposed moves. If overlapping date intervals
 * are found, errors are set using the provided `setError` function. This function ensures that
 * duplicate or intersecting schedules across multiple items are flagged for correction.
 *
 * @param {MultiMoveFormItemData[]} proposedMoves - An array of proposed movement data, each containing
 * pertinent details like asset IDs, ship dates, and delivery dates.
 * @param {UseFormSetError<MultiMoveFormData>} setError - A function used to register validation
 * errors for the respective fields in the form.
 * @returns {Map<number, boolean>} A map where the keys are the indices of the rows in the `proposedMoves`
 * array, and the values are booleans indicating whether an error was detected for that row.
 *
 */

export function checkMultiMoveIntersections(
  proposedMoves: MultiMoveFormItemData[],
  setError: UseFormSetError<MultiMoveFormData>
): Map<number, boolean> {
  /* eslint-disable @typescript-eslint/restrict-template-expressions */

  const rowsWithErrors = new Map<number, boolean>();
  // Check for controlled errors that exist for this component.
  // These errors affect multiple data rows simultaneously
  // Currently the only error that is checked is the ship/delivery intersection
  for (let i = 0; i < proposedMoves.length; i++) {
    if (!rowsWithErrors.has(i)) {
      rowsWithErrors.set(i, false);
    }
    const currentMove = proposedMoves[i];
    if (currentMove.asset && currentMove.asset.id) {
      for (let j = i + 1; j < proposedMoves.length; j++) {
        const comparisonMove = proposedMoves[j];
        if (
          comparisonMove.asset &&
          comparisonMove.asset.id &&
          currentMove.asset.id === comparisonMove.asset.id
        ) {
          // Handle identical ships
          if (
            currentMove.shipDate &&
            comparisonMove.shipDate &&
            currentMove.shipDate === comparisonMove.shipDate
          ) {
            setError(`moves.${i}.shipDate`, {
              type: 'custom',
              message: overlapErrorMessage,
            });
            rowsWithErrors.set(i, true);
            setError(`moves.${j}.shipDate`, {
              type: 'custom',
              message: overlapErrorMessage,
            });
            rowsWithErrors.set(j, true);
          }

          // Handle A ships between B
          if (
            currentMove.shipDate &&
            comparisonMove.shipDate &&
            comparisonMove.deliveryDate
          ) {
            const currentShipDate = currentMove.shipDate;
            const comparisonStart = comparisonMove.shipDate;
            const comparisonEnd = comparisonMove.deliveryDate;

            if (
              currentShipDate >= comparisonStart &&
              currentShipDate < comparisonEnd
            ) {
              setError(`moves.${i}.shipDate`, {
                type: 'custom',
                message: overlapErrorMessage,
              });
              rowsWithErrors.set(i, true);
              setError(`moves.${j}.shipDate`, {
                type: 'custom',
                message: overlapErrorMessage,
              });
              rowsWithErrors.set(j, true);
            }
          }
          // Handle B ships between A
          if (
            currentMove.shipDate &&
            currentMove.deliveryDate &&
            comparisonMove.shipDate
          ) {
            const currentShipDate = currentMove.shipDate;
            const currentDeliveryDate = currentMove.deliveryDate;
            const comparisonStart = comparisonMove.shipDate;

            if (
              comparisonStart >= currentShipDate &&
              comparisonStart < currentDeliveryDate
            ) {
              setError(`moves.${i}.shipDate`, {
                type: 'custom',
                message: overlapErrorMessage,
              });
              rowsWithErrors.set(i, true);
              setError(`moves.${j}.shipDate`, {
                type: 'custom',
                message: overlapErrorMessage,
              });
              rowsWithErrors.set(j, true);
            }
          }

          // Handle actual intersection
          if (
            currentMove.shipDate &&
            currentMove.deliveryDate &&
            comparisonMove.shipDate &&
            comparisonMove.deliveryDate
          ) {
            const currentStart = currentMove.shipDate;
            const currentEnd = currentMove.deliveryDate;
            const comparisonStart = comparisonMove.shipDate;
            const comparisonEnd = comparisonMove.deliveryDate;
            // Check if intervals overlap
            const intervalsOverlap =
              currentStart <= comparisonEnd && comparisonStart < currentEnd;
            if (intervalsOverlap) {
              setError(`moves.${i}.shipDate`, {
                type: 'custom',
                message: overlapErrorMessage,
              });
              rowsWithErrors.set(i, true);
              setError(`moves.${j}.shipDate`, {
                type: 'custom',
                message: overlapErrorMessage,
              });
              rowsWithErrors.set(j, true);
            }
          }
        }
      }
    }
  }

  return rowsWithErrors;
}

export type MultiMoveFormKey =
  | 'asset'
  | 'origin'
  | 'destination'
  | 'shipDate'
  | 'deliveryDate';
export type MultiMoveRowStatus =
  | 'valid'
  | 'invalid'
  | 'uninitialized'
  | 'pending'
  | 'unknown';
const formKeys = [
  'asset',
  'origin',
  'destination',
  'shipDate',
  'deliveryDate',
] as const;

export interface PlannedMove extends MultiMoveFormItemData {
  status: MultiMoveRowStatus;
  isValidating: boolean;
  altered: {
    asset: boolean;
    origin: boolean;
    destination: boolean;
    shipDate: boolean;
    deliveryDate: boolean;
  };
  lastIntersectionError?: PlanMultiMoveIntersection;
  runAfter?: number;
}

/**
 * Determines the validation status of a move based on its ID from the provided list of planned moves.
 *
 * @param {PlannedMove[]} moves - An array of planned move objects to be searched.
 * @param {string} id - The identifier of the move whose status is to be retrieved.
 * @return {MultiMoveRowStatus} The validation status of the move. Returns 'unknown' if no match is found.
 */
export function getPlannedMoveValidationStatus(
  moves: PlannedMove[],
  id: string
): MultiMoveRowStatus {
  const status = moves.find((item) => item.id.toString() === id);
  if (status) {
    return status.status;
  }
  return 'unknown';
}

/**
 * Retrieves the details of a specific row based on the provided ID.
 *
 * @param {PlannedMove[]} moves - An array of PlannedMove objects to search through.
 * @param {string} id - The ID of the row to find within the moves array.
 * @return {PlannedMove | undefined} The details of the row matching the provided ID, or undefined if no match is found.
 */
export function getPlannedMoveDetails(
  moves: PlannedMove[],
  id: string
): PlannedMove | undefined {
  const row = moves.find((item) => item.id.toString() === id);
  if (row) {
    return row;
  }
  return undefined;
}

type FieldErrorArray = Record<MultiMoveFormKey, FieldError>;

/**
 * Checks if the provided error object contains custom error types.
 *
 * @param {FieldError | FieldErrorArray | undefined} error - The error object to inspect. Can be undefined, a single error, or an array of errors.
 * @return {boolean} Returns true if any error in the provided object has a type of 'custom', otherwise false.
 */
export function errorHasCustomErrors(
  error: FieldError | FieldErrorArray | undefined
): boolean {
  if (!error) {
    return false;
  }

  if (formKeys.some((key) => key in error)) {
    return (Object.values(error) as FieldError[]).some((item) => {
      return item.type === 'custom';
    });
  }

  return (error as FieldError).type === 'custom';
}
